import React, { useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import Layout from "../components/layout"
import ArenaTeam from "../images/arena-team.png"
import CreativeTeam from "../images/creative-team.jpg"
import JaideepGopinath from "../images/JaideepGopinath.jpg"
import LinkdinDark from "../images/LinkdinDark.svg"
import MitsyLopezBaranello from "../images/MitsyLopezBaranello.jpg"
import PPCTeam from "../images/PPCTeam.jpg"
import RajivParikh from "../images/RajivParikh.jpg"
import SajjanKanukolanu from "../images/SajjanKanukolanu.jpg"
import SanjeevDhanraj from "../images/SanjeevDhanraj.jpg"
import SanjivParikh from "../images/SanjivParikh.jpg"
import ShayPhilips from "../images/ShayPhilips.jpg"
import TeamLunch from "../images/TeamLunch.jpg"
import VinodNambiar from "../images/VinodNambiar.jpg"
import Shapefour from "../images/bannerElement/white-design-element-four.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import HotelResortLeft from "../images/hotelsandresortsleft.jpg"
import HotelResortRight from "../images/hotelsandresortsright.jpg"
import linkdin from "../images/linkdin-icon.svg"
import "../styles/leadership-team.css"
import VideoBanner from "../video/leadership-team-banner-video.mp4"

export default function LeadershipTeam() {
  const [openModal, setOpenModal] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)
  const modalRef = useRef(null)

  const openModalForMember = member => {
    setSelectedMember(member)
    setOpenModal(true)
    if (modalRef.current) {
      modalRef.current.scrollTop = 0
    }
  }

  const closeModal = () => {
    setOpenModal(false)
  }
  const members = [
    {
      id: 1,
      name: "Rajiv Parikh",
      designation: "Founder & CEO",
      image: RajivParikh,
      description:
        "Our visionary CEO brings over 20 years of management experience in marketing, technology, and services, with a proven track record of driving innovation and growth. With previous management roles at industry giants like AltaVista, Sun Microsystems, NCR, and AT&T, and a strong educational background featuring an MBA from Harvard Business School and a BS in Electrical Engineering from the University of New Hampshire, our CEO is a sought-after speaker at industry forums, sharing his expertise on social media and search marketing trends to position our company for continued success in the digital landscape.",
      linkdinURL: "https://www.linkedin.com/in/rajivparikh",
    },
    {
      id: 2,
      name: "Shay Philips",
      designation: "Board Member",
      image: ShayPhilips,
      description:
        'Shay, a visionary executive with over 20 years of experience in the telecommunications industry, is renowned for launching innovative products that leverage cutting-edge technologies like 5G. Her expertise in marketing, operations, and strategy, combined with her ability to create structures that promote scale and growth in start-up environments, has led to her national recognition by the Career Communications Group with the "Black Engineer of the Year Award for Outstanding Technical Contribution – Industry." Holding a BA from DePaul University in Business Management and Marketing, Shay brings a well-rounded perspective to her roles, driving results through the seamless integration of technical innovations and sound business strategies.',
      linkdinURL: "https://www.linkedin.com/in/shaylphillips/",
    },
    {
      id: 3,
      name: "Mitsy Lopez Baranello",
      designation: "Board Member",
      image: MitsyLopezBaranello,
      description:
        "Mitsy Lopez, Group Vice President of Strategy at Huge, IPG's end-to-end experience agency, leads the Strategy group in the Brooklyn office. With her expertise in digital marketing and e-commerce, she spearheads large and complex e-commerce engagements, driving results for clients in the rapidly evolving digital landscape. Previously, Mitsy served as the North American digital and CRM strategy lead for Citibank at Publicis and the Corporate Strategy lead strategist for Lexus' hybrid and performance vehicles at Toyota Motor Sales, USA. Her impressive background, including a B.S. from Cornell University and an MBA from the UCLA Anderson School of Management, provides a solid foundation for her innovative solutions that drive growth and success for her clients.",
      linkdinURL: "https://www.linkedin.com/in/mitsylb",
    },
  ]
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Leadership Team | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div className="teamstyle">
          <section id="Banner">
            <div class="bgVideo">
              <video
                width="100%"
                playsInline
                muted
                autoPlay
                loop
                className="video-js BiggerScreen"
                data-setup="{'controls': false, 'autoplay': true, 'preload': true, 'loop':true }"
              >
                <source src={VideoBanner} type="video/mp4" />
              </video>
            </div>
            <div className="bannerContent">
              <div className="container">
                <div className="banner-holder">
                  <div className="images">
                    <div className="img-one image-shape">
                      <img src={Shapeone} alt="Image" />
                    </div>
                    <div className="img-two image-shape">
                      <img src={Shapetwo} alt="Image" />
                    </div>
                    <div className="img-three image-shape">
                      <img src={Shapethree} alt="Image" />
                    </div>
                    <div className="img-four image-shape">
                      <img src={Shapefour} alt="Image" />
                    </div>
                  </div>
                  <div className="title">
                    <div className="banner-title">
                      <h1>Meet our growth leaders</h1>
                      <p>
                        Discover the minds fostering creativity, collaboration,
                        and client success at Position<sup>2</sup>
                      </p>
                      <a
                        className="button"
                        id="3dsolutionvideo"
                        href="/contact-us"
                      >
                        Get in touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div className="container">
              <h2>
                The driving force behind Position<sup>2</sup>
              </h2>
              <p>
                We are Position<sup>2</sup>, a passionate team dedicated to
                helping businesses unlock their true potential through the power
                of innovation and AI-driven growth marketing. With over two
                decades of experience, we have established ourselves as a leader
                in the industry, empowering brands to scale their success and
                achieve remarkable results.
              </p>
            </div>
          </section>
          <section id="board-of-director">
            <div className="container">
              <h2>Our Board of Directors</h2>
              <div className="team-col">
                {members.map(member => (
                  <div className="each-member" key={member.id}>
                    <div className="holder">
                      <div className="img-wrap">
                        <img
                          src={member.image}
                          alt="Image"
                          className="team-img"
                        />
                        {/* <object type="image/svg+xml" data={member.image} aria-label="Rajiv Parikh" className="team-img"></object> */}
                        <div className="overlay"></div>
                      </div>
                      <div className="title-holder">
                        <h4 onClick={() => openModalForMember(member)}>
                          {member.name}
                        </h4>
                        <div className="designation">
                          <p>{member.designation}</p>
                          <a href={member.linkdinURL} target="_blank">
                            <img src={linkdin} alt="LinkedIn" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <Modal open={openModal} onClose={closeModal} center ref={modalRef}>
            {selectedMember && (
              <div className="team-description" tabIndex={0}>
                <div className="img-wrap">
                  <img src={selectedMember.image} alt="Image" />
                  {/* <object type="image/svg+xml" data={selectedMember.image} aria-label="Rajiv Parikh" className="img-team"></object> */}
                </div>
                <div className="wrap">
                  <div className="inner-content">
                    <div className="name-desg">
                      <div>
                        <h4>{selectedMember.name}</h4>
                        <p>{selectedMember.designation}</p>
                      </div>
                    </div>
                    <div>
                      <p>{selectedMember.description}</p>
                      <a
                        className="link-icon"
                        href={selectedMember.linkdinURL}
                        target="_blank"
                      >
                        <img src={LinkdinDark} alt="Image" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal>
          <section id="senior-director">
            <div className="container">
              <h2>Our Growth Leaders</h2>
              <div className="team-col">
                <div className="each-member" id="team-no-description">
                  <div className="holder">
                    <div className="img-wrap">
                      <img src={VinodNambiar} alt="Image" />
                      <div className="overlay"></div>
                    </div>
                    <div className="title-holder">
                      <h4>Vinod Nambiar</h4>
                      <div className="designation">
                        <p>Co-Founder &amp; MD</p>
                        <a
                          href="https://www.linkedin.com/in/vinodnambiarposition2/"
                          target="_blank"
                        >
                          <img src={linkdin} alt="Image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="each-member" id="team-no-description">
                  <div className="holder">
                    <div className="img-wrap">
                      <img src={SajjanKanukolanu} alt="Image" />
                      <div className="overlay"></div>
                    </div>
                    <div className="title-holder">
                      <h4>Sajjan Kanukolanu</h4>
                      <div className="designation">
                        <p>VP, Global Delivery</p>
                        <a
                          href="https://www.linkedin.com/in/sajjank/"
                          target="_blank"
                        >
                          <img src={linkdin} alt="Image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="each-member" id="team-no-description">
                  <div className="holder">
                    <div className="img-wrap">
                      <img src={SanjivParikh} alt="Image" />
                      <div className="overlay"></div>
                    </div>
                    <div className="title-holder">
                      <h4>Sanjiv Parikh</h4>
                      <div className="designation">
                        <p>VP, Growth</p>
                        <a
                          href="https://www.linkedin.com/in/sanjivparikh1/"
                          target="_blank"
                        >
                          <img src={linkdin} alt="Image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="each-member nomargin" id="team-no-description">
                  <div className="holder">
                    <div className="img-wrap">
                      <img src={SanjeevDhanraj} alt="Image" />
                      <div className="overlay"></div>
                    </div>
                    <div className="title-holder">
                      <h4>Sanjeev Dhanraj</h4>
                      <div className="designation">
                        <p>VP, Digital Transformation</p>
                        <a
                          href="https://www.linkedin.com/in/sanjeevdhanaraj/"
                          target="_blank"
                        >
                          <img src={linkdin} alt="Image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="each-member nomargin" id="team-no-description">
                  <div className="holder">
                    <div className="img-wrap">
                      <img src={DeliaPerla} alt="Image" />
                      <div className="overlay"></div>
                    </div>
                    <div className="title-holder">
                      <h4>Delia Perla</h4>
                      <div className="designation">
                        <p>Senior Director, Revenue</p>
                        <a
                          href="https://www.linkedin.com/in/deliaperla/"
                          target="_blank"
                        >
                          <img src={linkdin} alt="Image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="each-member nomargin" id="team-no-description">
                  <div className="holder">
                    <div className="img-wrap">
                      <img src={JaideepGopinath} alt="Image" />
                      <div className="overlay"></div>
                    </div>
                    <div className="title-holder">
                      <h4>Jaideep Gopinath</h4>
                      <div className="designation">
                        <p>VP, Client Success</p>
                        <a
                          href="https://www.linkedin.com/in/jaideepgopinath/"
                          target="_blank"
                        >
                          <img src={linkdin} alt="Image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="team-gallery">
            <div className="container">
              <h2>Our team</h2>
              <div class="gallery-wrapper">
                <div class="gallery-left">
                  <div class="primary-img">
                    <img src={HotelResortLeft} alt="Image" />
                  </div>
                  <div class="twogalleryimg">
                    <div class="secondaryimgone">
                      <img src={ArenaTeam} alt="Image" />
                    </div>
                    <div class="secondaryimgtwo">
                      <img src={PPCTeam} alt="Image" />
                    </div>
                  </div>
                </div>
                <div class="gallery-right">
                  <div class="twogalleryimg">
                    <div class="secondaryimgone">
                      <img src={CreativeTeam} alt="Image" />
                    </div>
                    <div class="secondaryimgtwo">
                      <img src={TeamLunch} alt="Image" />
                    </div>
                  </div>
                  <div class="primary-img">
                    <img src={HotelResortRight} alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section" className="cybersecurity">
            <div className="container">
              <h2>Join our team of innovators and change-makers</h2>
              <a class="button" id="3dsolutionvideo" href="/careers/">
                Join our team
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
